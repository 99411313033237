import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Mensajeinput from './Mensajeinput';
import DialogodisplayFU from './DialogodisplayFU';
import useSessionStorageState from 'use-session-storage-state'


const DialogosDeEscuela = () => {
    const [ok, setOk] = useState(false);
    const logged = useSessionStorageState('logged')[0];
    const usuario = useSessionStorageState('usuario')[0];
    const admin = usuario.TIPUSUARIO === 2 ? true : false
    const [fotosdialogs, setFotosDialogs] = useState([]);
    const [docsdialogs, setDocsDialogs] = useState([]);
    const [dialogos, setDialogos] = useState([]);
    const [selected, setSelected] = useState('');
    const [listdestinatarios, setListdestinatarios] = useState([]);
    // const [uniquedialogs,setUniqueDialogs] = useState([])
    //funciones

    const fetchUsers = async () => {
        try {
            const response = await axios.post("https://familia.caminantes.cl/usuarios", {
                method: "POST",
                mode: "no-cors",  // Change the mode to CORS
                params: {
                    logged: logged,
                    admin: admin
                }
            });
            let usuarios = [];
            if (admin === false) {
                usuarios = response.data.data.filter((s) => {
                    return (s.TIPUSUARIO !== 1)
                });
            }
            else {
                usuarios = response.data.data
            }
            setListdestinatarios(usuarios)
        } catch (error) {
            console.error(error);
        }
    };
    const enviaMail = (input) => {
        axios.post('https://php.caminantes.cl/mailescuela.php', input)
    }
    function recibeMensaje(mensaje) {
        if (mensaje.cancelar === false) {
            if (mensaje.msg !== '') {
                envMensaje(mensaje);
                fetchDialogos();
            }
            else {
                alert('Escribe el texto del mensaje')
            }

        }
        setOk(!ok)

    }
    const envMensaje = (mensaje) => {
        const postMensaje = async () => {
            if (mensaje.msg !== "") {
                try {
                    const response = await axios.post("https://familia.caminantes.cl/postdialogo", {
                        method: "POST",
                        mode: "no-cors",  // Change the mode to CORS
                        params: {
                            remitente: usuario.CODUSUARIO,
                            mensaje: "'" + mensaje.msg + "'",
                            destinatario: "'" + selected.CODUSUARIO + "'",
                            link: "'" + mensaje.link + "'"
                        }
                    });
                    handleUploadDoc(mensaje.doc, response.data.data.insertId);
                    handleUploadFoto(response.data.data.insertId + "-", mensaje.file);
                    fetchDialogos()
                } catch (error) {
                    console.error(error);
                }
            }
        }
        postMensaje();
        let input = { remitente: usuario.NOMUSUARIO, mensaje: mensaje.msg, emaildestinatario: selected.MAILUSUARIO, destinatario: selected.NOMUSUARIO }
        enviaMail(input)

    }
    const handleUploadDoc = async (doc, coddialogo) => {
        if (doc) {
            let filename = coddialogo.toString();
            const formData = new FormData();
            formData.append('file', doc);
            formData.append('text', filename);
            await fetch('https://php.caminantes.cl/uploadDoc.php', {
                method: 'POST',
                body: formData,
            });
        }
    };
    const handleUploadFoto = async (nomfoto, image) => {
        const formData = new FormData();
        formData.append('dir', '../escuela/images/dialogs/');
        formData.append('nom', nomfoto);
        formData.append('file', image);
        await fetch('https://php.caminantes.cl/uploadFoto.php', {
            method: 'POST',
            mode: "no-cors",
            body: formData
        }).then(() => { fetchFotosDialogs() })
    }
    const fetchDialogos = async () => {
        if (logged === true) {
            try {
                const response = await axios.post('https://familia.caminantes.cl/getdialogos/', {
                    method: "POST",
                    mode: "no-cors",  // Change the mode to CORS
                    params: {
                        usuario: usuario.CODUSUARIO,
                        logged: logged
                    }
                }
                );
                let dialogosFetched = response.data.data
                setDialogos(dialogosFetched)
            } catch (error) {
                console.error(error);
            }
        }
    };
    const fetchFotosDialogs = async () => {
        var formData = new FormData();
        formData.append('logged', logged)
        await axios.post('https://php.caminantes.cl/listaFotosDialogs.php', formData,
            {
                method: "POST",
                mode: "no-cors",  // Change the mode to CORS
            })
            .then(function (response) {

                setFotosDialogs(response.data);
            });
    }
    const fetchDocsDialogs = async () => {
        var formData = new FormData();
        formData.append('logged', logged)
        await axios.post('https://php.caminantes.cl/listaDocs.php', formData,
            {
                method: "POST",
                mode: "no-cors",  // Change the mode to CORS
            })
            .then(function (response) {
                setDocsDialogs(response.data);
            });
    }
    function delDialogo(id, texto) {
        if (window.confirm('Borrar este mensaje: "' + texto + '" ?' + id)) {
            const delDial = async () => {
                if (id !== null) {
                    try {
                        await axios.delete("https://familia.caminantes.cl/dialogos/borrar/'" + id + "'", {
                            method: "POST",
                            mode: "no-cors",  // Change the mode to CORS
                            params: {
                                id: id,
                            },
                        });
                        fetchDialogos();
                    } catch (error) {
                        console.error(error);
                    }
                }
            };
            delDial();

        }
    }
    useEffect(() => {
        fetchFotosDialogs();
        fetchDocsDialogs();
        fetchUsers();
        fetchDialogos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, ok]);
    if (logged) {
        return (
            <div className=" rounded-lg">
                <div id='mensajes' className='pt-1 bg-sky-500 rounded-lg px-1 ' hidden={ok}>
                    <div className='font-bold text-white text-center'>
                        {'Click en una persona para enviarle un mensaje'}
                    </div>
                    <div id='fotdest' className='flex flex-wrap  pt-1'>
                        {listdestinatarios &&
                            listdestinatarios.map((d, index) => {
                                return (
                                    <div key={index}
                                        className='cursor-pointer  flex w-28 h-28 mx-auto rounded-lg p-0 pb-1'
                                        onClick={() => { setSelected(d); setOk(true) }} >
                                        <img alt={d.NOMUSUARIO} className='flex rounded-lg h-full w-full'
                                            src={"https://escuela.caminantes.cl/images/retratos/" + d.CODUSUARIO + ".jpg"} >
                                        </img>
                                    </div>
                                )
                            })}
                    </div>
                </div>
                <div id='escribe msg' className='rounded-lg pt-1'>
                    {selected.CODUSUARIO === undefined ?
                        <>
                            {dialogos.map((resp, index) =>
                                <div key={index} className='pt-1 rounded-lg'>
                                    <div id='display'>
                                        <DialogodisplayFU
                                            index={index}
                                            FECDIALOGO={resp.FECDIALOGO}
                                            CODDIALOGO={resp.CODDIALOGO}
                                            DESTINATARIO={resp.NOMDESTINATARIO}
                                            REMITENTE={resp.NOMREMITENTE}
                                            TXTDIALOGO={resp.TXTDIALOGO}
                                            link={resp.LINK}
                                            nomfoto={fotosdialogs.filter((f) => { return f.slice(0, f.search("-")) === resp.CODDIALOGO.toString() })}
                                            nomdoc={docsdialogs.filter((f) => { return f.slice(0, f.search("-")) === resp.CODDIALOGO.toString() })[0]}
                                        />
                                    </div>
                                </div>)
                            }
                        </>
                        :
                        <div id='seldestinatario' className=''>
                            <div className='grid grid-cols-3' >
                                <div className='text-center my-auto w-fit  p-1  cursor-pointer bg-violet-700 text-white rounded-lg'>
                                    {"Tus diálogos con " + selected.NOMUSUARIO}
                                </div>
                                <div>
                                    <img
                                        src={'https://escuela.caminantes.cl/images/retratos/' + selected.CODUSUARIO + ".jpg"}
                                        className='h-28 w-28 p-1 rounded-lg mx-auto' alt='usuario' >
                                    </img>
                                </div>
                                <div onClick={() => { setOk(false); setSelected('') }}
                                    className='text-center my-auto w-fit  p-1  cursor-pointer bg-violet-700 text-white rounded-lg'>
                                    {'Cambiar destinatario'}
                                </div>
                            </div>


                            <div id='cajaMensaje' className='bg-sky-500 rounded-lg pt-1 p-4 grid-cols-2 border-2 
                                 px-1 py-0 '>
                                <div className='' >
                                    <Mensajeinput
                                        funcion={recibeMensaje}
                                        borrar={true}
                                        plantilla={'Escribe aquí tu mensaje para ' + selected.NOMUSUARIO}
                                        confoto={true}
                                        condoc={true}
                                        conlink={true}
                                        cancelar={true}
                                        id={new Date()}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {dialogos.length > 0 && selected !== '' ? dialogos
                        .filter((d) => { return (d.REMITENTE.toString() === selected.CODUSUARIO.toString() || d.DESTINATARIO.toString() === selected.CODUSUARIO.toString()) })
                        .map((resp, index) =>
                            <div key={index} className='pt-1 rounded-lg'>
                                <div id='display'>
                                    <DialogodisplayFU
                                        index={index}
                                        CODUSUARIO={usuario.CODUSUARIO}
                                        FECDIALOGO={resp.FECDIALOGO}
                                        CODDIALOGO={resp.CODDIALOGO}
                                        DESTINATARIO={resp.NOMDESTINATARIO}
                                        CODREMITENTE={resp.REMITENTE}
                                        REMITENTE={resp.NOMREMITENTE}
                                        TXTDIALOGO={resp.TXTDIALOGO}
                                        borrar={true}
                                        link={resp.LINK}
                                        nomfoto={fotosdialogs.filter((f) => { return f.slice(0, f.search("-")) === resp.CODDIALOGO.toString() })}
                                        nomdoc={docsdialogs.filter((f) => { return f.slice(0, f.search("-")) === resp.CODDIALOGO.toString() })[0]}
                                    />
                                </div>
                                <div>
                                    {resp.REMITENTE === usuario.CODUSUARIO ?
                                        <div
                                            className="h-8 bg-violet-600 rounded-lg text-white w-fit py-1 px-1 "
                                            onClick={() => delDialogo(resp.CODDIALOGO, resp.TXTDIALOGO)}>{'Borrar'}
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>)
                        : ''
                    }
                </div>
            </div>
        )
    }
}
export default DialogosDeEscuela;